// Imports
import styled from 'styled-components/macro';
import Button from '../button';


// Styled components
const Wrapper = styled.figure`
	width: 100%;
	max-width: 43.875rem;
	
	padding: 1.875rem 6.25rem;
	
	border-radius: 624.9375rem;
	background-color: hsl(206, 69%, 35%);
	
	@media (max-width: 47.9375rem) {
		border-radius: 2.5rem;
		padding: 1.875rem;
	}
`;

const Heading = styled.figcaption`
	color: hsl(206, 70%, 88%);
	text-align: center;
	font-size: 1.25rem;
	line-height: 1;
	margin-bottom: 1.25rem;
	
	> strong {
		display: block;
		color: hsl(0, 0%, 100%);
		text-align: center;
		font-family: Zilla Slab;
		font-size: 3.125rem;
		font-weight: 600;
		line-height: 1;
		
		margin-bottom: 1.25rem;
		
		@media (max-width: 48rem) {
			font-size: 2.5rem;
		}
	}
`;

const ButtonGroup = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 0.625rem;
	text-align: center;
	
	@media (max-width: 47.9375rem) {
		gap: 0.9375rem;
		flex-direction: column;
		
		> a {
			justify-content: center;
			padding: 0.625rem 1.25rem;
			width: 100%;
		}
	}
`;


// Function component
const CallToAction: React.FC = () => (
	<Wrapper>
		<Heading>
			<strong>Start for free today</strong>
			Build a website that wows your community in no time
		</Heading>
		
		<ButtonGroup>
			<Button
				backgroundColor='hsl(96, 54%, 59%)'
				fontColor='hsl(0, 0%, 100%)'
				href={`https://start.${process.env.REACT_APP__ENVIRONMENT_HOSTNAME}`}
				rel='noopener'
			>
				Start for free
			</Button>
			
			{/* 
				LATER: Once the migration process is overhaul, uncomment this code
				-> https://gitlab.com/sparksuite-family/hoa-express/product-changes/-/issues/137
				<Button
					backgroundColor='hsl(206, 45%, 64%)'
					fontColor='hsl(0, 0%, 100%)'
					href={`https://start.${process.env.REACT_APP__ENVIRONMENT_HOSTNAME}`} // LATER: Replace this with an appropriate link after migration process is overhauled
					rel='noopener'
				>
					Migrate existing site
				</Button> 
			*/}
		</ButtonGroup>
	</Wrapper>
);

export default CallToAction;
