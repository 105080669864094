// Imports
import { faUsers } from '@fortawesome/pro-regular-svg-icons';
import Feature from '..';


// Banner
const feature: React.ComponentProps<typeof Feature> = {
	banner: {
		label: { icon: faUsers, text: 'Store residents' },
		heading: 'Store your residents and their contact info in one place',
		description:
			'Residents register for accounts, which require approval before becoming active. Then, restrict pages to residents only, send communications to specific residents, and assign administrative privileges to individual people.',
		screenshot: {
			width: 900,
			height: 634,
			src: '/images/screenshots/features/store-residents/banner.webp',
		},
	},
	pitch: { text: 'Unlock powerful abilities by letting residents register', textMaxWidth: 'min(38.0625rem, 50.75vw)' },
	tiles: {
		leftColumn: [
			{
				heading: 'Build page restrictions',
				screenshot: {
					width: 980,
					height: 532,
					src: (layout) => `/images/screenshots/features/store-residents/${layout}/page-restrictions.webp`,
				},
			},
			{
				heading: 'Require approval before activating',
				screenshot: {
					width: 724,
					height: 142,
					src: (layout) => `/images/screenshots/features/store-residents/${layout}/require-approval.webp`,
				},
			},
			{
				heading: 'Create custom groups',
				screenshot: {
					width: 912,
					height: 342,
					src: (layout) => `/images/screenshots/features/store-residents/${layout}/custom-groups.webp`,
				},
			},
			{
				heading: 'Customize what info you collect',
				screenshot: {
					width: 980,
					height: 332,
					src: (layout) => `/images/screenshots/features/store-residents/${layout}/customize-info.webp`,
				},
			},
			{
				heading: 'Invite residents to join',
				screenshot: {
					width: 980,
					height: 600,
					src: (layout) => `/images/screenshots/features/store-residents/${layout}/invite.webp`,
				},
			},
			{
				heading: 'Import residents in bulk',
				screenshot: {
					width: 844,
					height: 160,
					src: (layout) => `/images/screenshots/features/store-residents/${layout}/import.webp`,
				},
			},
		],
		rightColumn: [
			{
				heading: 'Organize members into households',
				screenshot: {
					width: 980,
					height: 242,
					src: (layout) => `/images/screenshots/features/store-residents/${layout}/households.webp`,
				},
			},
			{
				heading: 'Separate administrative privileges',
				screenshot: {
					width: 980,
					height: 238,
					src: (layout) => `/images/screenshots/features/store-residents/${layout}/admin-privileges.webp`,
				},
			},
			{
				heading: 'Detailed contact & privacy preferences',
				screenshot: {
					width: 940,
					height: 604,
					src: (layout) => `/images/screenshots/features/store-residents/${layout}/preferences.webp`,
				},
			},
			{
				heading: 'Address validation & correction',
				screenshot: {
					width: 980,
					height: 152,
					src: (layout) => `/images/screenshots/features/store-residents/${layout}/validation.webp`,
				},
			},
			{
				heading: 'Powerful export tools',
				screenshot: {
					width: 932,
					height: 552,
					src: (layout) => `/images/screenshots/features/store-residents/${layout}/export-tools.webp`,
				},
			},
			{
				heading: 'Search a multitude of attributes',
				screenshot: {
					width: 668,
					height: 130,
					src: (layout) => `/images/screenshots/features/store-residents/${layout}/search.webp`,
				},
			},
		],
	},
	testimonials: [
		{
			text: 'It’s so cool how easy it was to set up and invite members. I recruited 15 community members within 2 weeks, for starters... and I feel like a hero for our cause.',
			source: {
				name: 'Chris',
				hoa: 'Mulhern Lake Association',
				url: 'https://mulhernknightslake.communitysite.com/',
			},
		},
	],
};

export default feature;
