// Imports
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import Dropdown from './dropdown';
import Logo from './logo';
import NavbarLink, { styling } from './link';
import Button from '../button';
import MobileNavigation from './mobile-navigation';
import featureLinks from './feature-links';
import resourceLinks from './resource-links';
import { useLocation } from 'react-router-dom';
import useUpdateOnlyEffect from '../../../shareables/foundation/front-end/utils/use-update-only-effect';
import { useAppSelector } from '../../../shareables/foundation/front-end/redux/hooks';
import UserDropdown from './user-dropdown';
import generateUserDropdownLinks from './generate-user-dropdown-links';
import { AppCommonState } from '../../../redux/types';


// Styled components
const Wrapper = styled.header<{ transitionBackgroundColor: boolean }>`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	
	position: sticky;
	top: 0;
	z-index: 9999;
	
	backdrop-filter: blur(8px);
	
	transition:
		background-color 0.4s cubic-bezier(0.4, 0, 0.2, 1),
		box-shadow 0.4s cubic-bezier(0.4, 0, 0.2, 1);
	
	${({ transitionBackgroundColor }) =>
		transitionBackgroundColor
			? css`
					background-color: hsl(0 0% 100% / 0.9);
					box-shadow: 0 0.0625rem hsl(206, 60%, 94%);
			  `
			: css`
					background-color: hsl(206, 60%, 94%);
			  `}
	
	@supports (animation-range: 0vh 10vh) {
		transition: unset;
		
		@keyframes fade-background {
			0% {
				background-color: hsl(206, 60%, 94%);
			}
			
			100% {
				background-color: hsl(0 0% 100% / 0.9);
				box-shadow: 0 0.0625rem hsl(206, 60%, 94%);
			}
		}
		
		animation: fade-background linear forwards;
		animation-timeline: scroll();
		animation-range: 0vh 10vh;
	}
`;

const Container = styled.nav`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	max-width: 75rem;
	
	padding: 1.25rem;
	padding-bottom: 1rem;
	
	@media (max-width: 48rem) {
		padding: 0.94rem;
		padding-bottom: 0.752rem;
	}
`;

const Demo = styled.a`
	${styling}
`;

const SignOut = styled.a`
	${styling}
	display: none;
	
	@media (max-width: 70rem) {
		display: inline;
	}
	
	@media (max-width: 48rem) {
		display: none;
	}
`;

const Menu = styled.div`
	display: flex;
	align-items: center;
`;

const NavigationLinks = styled.div`
	display: inline-grid;
	grid-auto-flow: column;
	gap: 1.875rem;
	align-items: center;
	
	@media (max-width: 70rem) {
		display: none;
	}
`;

const RightMenuNavigation = styled(NavigationLinks)<{ mobileNavigationIsOpen?: boolean }>`
	gap: 1.25rem;
	
	@media (max-width: 70rem) {
		display: inline-grid;
	}
	
	&::after {
		content: '';
		opacity: 0;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 4.2rem;
		z-index: 2;
		background-color: #fff;
		pointer-events: none;
		
		${({ mobileNavigationIsOpen }) =>
			mobileNavigationIsOpen
				? css`
						@media (max-width: 70rem) {
							transition: opacity 0.2s ease-in-out;
							transition-delay: 0.2s;
							pointer-events: auto;
							opacity: 1;
						}
						
						@media (max-width: 48rem) {
							height: 3.1375rem;
						}
				  `
				: ''}
`;

const SignInOrSignUpLinks = styled(RightMenuNavigation)`
	@media (max-width: 48rem) {
		display: none;
	}
	
	&::after {
		content: none;
	}
	
	> * {
		z-index: 3;
	}
`;


// Define the accepted props
interface Props {
	storybookActiveDropdown?: string;
	storybookUser?: AppCommonState['user'];
}


// Function component
const Navbar: React.FC<Props> = ({ storybookActiveDropdown, storybookUser }) => {
	// Use state
	const [activeDropdown, setActiveDropdown] = useState<string | null>(storybookActiveDropdown ?? null);
	const [transitionBackgroundColor, setTransitionBackgroundColor] = useState(
		window.scrollY / window.innerHeight >= 0.1
	);
	
	
	// Use React Router functionality
	const location = useLocation();
	
	
	// Use Redux functionality
	const user = useAppSelector((state) => storybookUser ?? state.common.user);
	
	
	// Handle tracking scroll events and setting navbar background in response
	useEffect(() => {
		const handleScroll = () => {
			setTransitionBackgroundColor(window.scrollY / window.innerHeight >= 0.1);
		};
		
		window.addEventListener('scroll', handleScroll);
		
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);
	
	
	// Handle listening for clicks and auto-hiding any open menus
	useEffect(() => {
		// Ignore if no menu is open
		if (!activeDropdown) {
			return;
		}
		
		
		// Initialize object to track if the removal happens before the addition of the event listener
		//  -> We're using an object here so that arrow functions below capture the reference and not the value
		const removalTracker = {
			removed: false,
		};
		
		
		// This function is designed to handle every click
		const handleEveryClick = (event: MouseEvent): void => {
			// Make this happen asynchronously
			setTimeout(() => {
				// Type guard
				if (!(event.target instanceof Element)) {
					return;
				}
				
				
				// Ignore if we're clicking inside the navbar
				if (event.target.closest(`#site-navigation`) instanceof Element) {
					return;
				}
				
				
				// Hide dropdowns
				setActiveDropdown(null);
			}, 10);
		};
		
		
		// Add listener
		//  -> Force it to be async to fix: https://github.com/facebook/react/issues/20074
		setTimeout(() => {
			if (removalTracker.removed) {
				return;
			}
			
			document.addEventListener('click', handleEveryClick);
		}, 1);
		
		
		// Return function to remove listener
		return (): void => {
			removalTracker.removed = true;
			
			document.removeEventListener('click', handleEveryClick);
		};
	}, [activeDropdown]);
	
	
	// Close when navigating somewhere new
	useUpdateOnlyEffect(() => {
		setActiveDropdown(null);
	}, [location.pathname]);
	
	
	// Handle key down
	const handleKeyDown: React.KeyboardEventHandler<HTMLElement> = (e) => {
		if (e.key === 'Escape') {
			setActiveDropdown(null);
		}
	};
	
	
	// Handle blur
	const handleBlur: React.FocusEventHandler<HTMLElement> = (e) => {
		if (!e.relatedTarget) {
			return;
		}
		
		const containsFocusedElement = e.currentTarget.contains(e.relatedTarget);
		
		if (!containsFocusedElement) {
			setActiveDropdown(null);
		}
	};
	
	
	// Handle link click
	const handleLinkClick = () => setActiveDropdown(null);
	
	
	// Return JSX
	return (
		<Wrapper transitionBackgroundColor={transitionBackgroundColor}>
			<Container id='site-navigation' aria-label='HOA Express' onKeyDown={handleKeyDown} onBlur={handleBlur}>
				<Menu>
					<Logo onClick={handleLinkClick} />
					
					<NavigationLinks>
						<Dropdown
							name='features'
							items={featureLinks}
							text='Features'
							activeDropdown={activeDropdown}
							setActiveDropdown={setActiveDropdown}
							columns={3}
							enableMenuItemHoverEffects
						/>
						<NavbarLink to='/pricing' onClick={handleLinkClick}>
							Pricing
						</NavbarLink>
						<Dropdown
							name='resources'
							items={resourceLinks}
							text='Resources'
							activeDropdown={activeDropdown}
							setActiveDropdown={setActiveDropdown}
							columns={2}
							enableMenuItemHoverEffects
						/>
						<Demo href='https://demo.communitysite.com/' target='_blank' rel='noopener' onClick={handleLinkClick}>
							Demo
						</Demo>
					</NavigationLinks>
				</Menu>
				
				<Menu>
					<RightMenuNavigation mobileNavigationIsOpen={activeDropdown?.startsWith('mobile-navigation')}>
						{user.signedIn ? (
							<React.Fragment>
								<UserDropdown
									name='user'
									items={generateUserDropdownLinks(user)}
									text={`Welcome, ${user.firstName ?? ''}`}
									activeDropdown={activeDropdown}
									setActiveDropdown={setActiveDropdown}
									columns={1}
									hideArrowOnMenuItemHover
									enableMenuItemHoverEffects
								/>
								
								<SignOut
									href={`https://auth.${
										process.env.REACT_APP__ENVIRONMENT_HOSTNAME
									}/sign-out?redirect=${encodeURIComponent(window.location.origin)}`}
									rel='noopener'
									onClick={() => {
										handleLinkClick();
										
										if (window.Intercom) {
											window.Intercom('shutdown');
										}
									}}
								>
									Sign out
								</SignOut>
							</React.Fragment>
						) : (
							<SignInOrSignUpLinks>
								<NavbarLink
									as='a'
									href={`https://app.${process.env.REACT_APP__ENVIRONMENT_HOSTNAME}`}
									rel='noopener'
									onClick={handleLinkClick}
								>
									Sign in
								</NavbarLink>
								<Button
									href={`https://start.${process.env.REACT_APP__ENVIRONMENT_HOSTNAME}`}
									rel='noopener'
									onClick={handleLinkClick}
									backgroundColor='hsl(96, 54%, 59%)'
									fontColor='#fff'
									scale={0.8}
									displayArrowIndicator
								>
									<span>Start for free</span>
								</Button>
							</SignInOrSignUpLinks>
						)}
						<MobileNavigation
							activeDropdown={activeDropdown}
							setActiveDropdown={setActiveDropdown}
							onLinkClick={handleLinkClick}
						/>
					</RightMenuNavigation>
				</Menu>
			</Container>
		</Wrapper>
	);
};

export default Navbar;
