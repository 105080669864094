// Imports
import { useEffect } from 'react';
import apiActions from '../../../shareables/integrations/api/redux/actions';
import * as Sentry from '@sentry/browser';
import { useAppDispatch, useAppSelector } from '../../../shareables/foundation/front-end/redux/hooks';
import { GetSelfResponse } from '../../../shareables/types/api/main/self/get';
import appCommonActions from '../../../redux/app-common-actions';


// Function component
const UserFunctionality: React.FC = () => {
	// Use Redux functionality
	const dispatch = useAppDispatch();
	const signedIn = useAppSelector((state) => state.common.user.signedIn);
	
	
	// Attempt to refresh the auth cookie
	useEffect(() => {
		// Return early if pre-rendering
		if (navigator.userAgent === 'ReactSnap') {
			return;
		}
		
		
		// Refresh auth token
		void fetch(`https://auth.${process.env.REACT_APP__ENVIRONMENT_HOSTNAME}/keep-alive`, {
			method: 'GET',
			mode: 'no-cors',
			cache: 'no-cache',
			credentials: 'include',
		});
	}, []);
	
	
	// Require user to sign in and retrieve details about them
	useEffect(() => {
		// Handle based on whether the user is signed in
		if (!signedIn) {
			// Call API
			void dispatch(
				apiActions.call<undefined, undefined, GetSelfResponse>({
					action: 'GET',
					uri: '/self',
					cancelOnRouteChange: false,
					completion: (json) => {
						// Dispatch action
						dispatch(
							appCommonActions.signedIn({
								id: json.response.id,
								name: json.response.name,
								firstName: json.response.firstName,
								lastName: json.response.lastName,
								email: json.response.email,
								profilePhotoURL: json.response.profilePhotoURL,
								reseller: json.response.reseller,
								preferenceID: json.response.preferenceID,
							})
						);
						
						
						// Boot Intercom, if available
						if (window.Intercom && 'intercom' in json.response && json.response.intercom) {
							window.Intercom('update', json.response.intercom);
						}
						
						
						// Store user for Sentry
						Sentry.configureScope((scope) => {
							scope.setUser({
								id: String(json.response.id),
								email: json.response.email,
							});
						});
					},
					handleMissingOrInvalidAuthentication: () => {
						// Do nothing
					},
				})
			);
		}
	}, [signedIn, dispatch]);
	
	
	// Render nothing
	return null;
};

export default UserFunctionality;
