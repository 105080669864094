// Imports
import { faArrowRightFromBracket, faHouseBuilding, faMessages, faSitemap } from '@fortawesome/pro-duotone-svg-icons';
import { AppCommonState } from '../../../redux/types';
import Dropdown from './dropdown';


/** Generates user dropdown links dynamically */
const generateUserDropdownLinks = (
	user: AppCommonState['user'],
	options?: { excludeSignOut?: boolean }
): React.ComponentProps<typeof Dropdown>['items'] => {
	const userDropdownLinks: React.ComponentProps<typeof Dropdown>['items'] = [
		{
			title: 'My communities',
			href: `https://app.${process.env.REACT_APP__ENVIRONMENT_HOSTNAME}`,
			icon: faHouseBuilding,
		},
		{
			title: 'Preferences',
			href: `https://preferences.${process.env.REACT_APP__ENVIRONMENT_HOSTNAME}/?who=${window.encodeURIComponent(
				user.preferenceID ?? ''
			)}`,
			icon: faMessages,
		},
	];
	
	if (user.reseller) {
		userDropdownLinks.push({
			title: 'Reseller Portal',
			href: `https://reseller.${process.env.REACT_APP__ENVIRONMENT_HOSTNAME}`,
			icon: faSitemap,
		});
	}
	
	if (!options?.excludeSignOut) {
		userDropdownLinks.push({
			title: 'Sign out',
			href: `https://auth.${process.env.REACT_APP__ENVIRONMENT_HOSTNAME}/sign-out?redirect=${encodeURIComponent(
				window.location.origin
			)}`,
			icon: faArrowRightFromBracket,
			openOnSamePage: true,
			onClick: () => {
				if (window.Intercom) {
					window.Intercom('shutdown');
				}
			},
		});
	}
	
	return userDropdownLinks;
};

export default generateUserDropdownLinks;
