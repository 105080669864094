// Imports
import React, { lazy, Suspense, useCallback, useEffect, useState } from 'react';
import { useAppDispatch } from '../../../shareables/foundation/front-end/redux/hooks';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import apiActions from '../../../shareables/integrations/api/redux/actions';
import formatCurrency from '../../../shareables/utils/project-specific/formatting/format-currency';
import company from '../../../shareables/company';
import Logo from '../../../shareables/front-end/svg-logo';
import { GetInvoiceParameters, GetInvoiceResponse } from '../../../shareables/types/api/main/invoice/get';
import SimplePageTemplate from '../../resources/simple-page-template';
import Box from './box';
import NotFound from './not-found';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh } from '@fortawesome/pro-regular-svg-icons';
import commonActions from '../../../shareables/foundation/front-end/redux/actions';
import parseMarkdown from '../../../shareables/utils/markdown/parse';


// Lazily-loaded components
const PaymentForm = lazy(() => import('./payment-form'));


// Styled components
const TopPart = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: auto auto;
	row-gap: 1rem;
	
	> div:nth-child(even) {
		text-align: right;
	}
	
	@media (max-width: 30rem) {
		align-items: center;
		grid-template-columns: 1fr;
		grid-auto-flow: row;
		row-gap: 0.75rem;
		
		> div:nth-child(even) {
			text-align: left;
		}
	}
`;

const LogoContainer = styled.div`
	width: 18.75rem;
	
	> svg {
		width: 100%;
		height: auto;
	}
	
	padding-right: 2em;
`;

const Content = styled.div`
	width: 100%;
	max-width: 50rem;
	
	display: flex;
	flex-direction: column;
	gap: 1rem;
	
	padding-top: 3.75rem;
`;

const TableContainer = styled.div`
	overflow: auto;
	overflow-y: hidden;
	max-width: 100%;
	width: 100%;
`;

const Table = styled.table`
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0;
	
	margin-top: 2rem;
	
	th {
		border-bottom: 0.0625rem solid hsl(206, 69%, 87%);
		font-size: 1.5rem;
		font-weight: 600;
		line-height: 1.2;
		
		padding-bottom: 0.625rem;
	}
	
	td {
		font-size: 1.125rem;
		line-height: 1.2;
		padding-top: 0.625rem;
		
		white-space: nowrap;
	}
	
	tr:not(:last-child) {
		> td {
			border-bottom: 0.0625rem solid hsl(206, 69%, 87%);
			padding-bottom: 0.625rem;
		}
	}
	
	td:first-child {
		padding-right: 0.5rem;
	}
	
	th:first-child {
		width: 100%;
		text-align: left;
	}
	
	th:last-child,
	td:last-child {
		text-align: center;
	}
`;

const LoadingWrapper = styled.div`
	margin: 3.75rem auto;
	margin-bottom: 0;
	font-size: 3.125rem;
	line-height: 1;
`;


// Define the accepted props
interface Props {
	storybookInvoice?: GetInvoiceResponse;
	storybookNotFound?: boolean;
	storybookShowPaymentForm?: boolean;
	storybookNumber?: string;
	storybookSecret?: string;
}


// Function component
const Invoice: React.FC<Props> = ({
	storybookInvoice,
	storybookNotFound,
	storybookShowPaymentForm,
	storybookNumber,
	storybookSecret,
}) => {
	// Use Redux functionality
	const dispatch = useAppDispatch();
	
	
	// Use React Router functionality
	const [searchParams] = useSearchParams();
	const number = storybookNumber ?? searchParams.get('n');
	const secret = storybookSecret ?? searchParams.get('s');
	
	
	// Use state
	const [invoice, setInvoice] = useState<GetInvoiceResponse | null>(storybookInvoice ?? null);
	const [notFound, setNotFound] = useState(storybookNotFound ?? false);
	
	
	// Handle immediate not found
	useEffect(() => {
		setNotFound(navigator.userAgent !== 'ReactSnap' && (!number || !secret));
	}, [secret, number]);
	
	
	// Function that loads the invoice
	const memoizedLoadInvoice = useCallback(() => {
		return new Promise<void>((resolve) => {
			if (!number || !secret) {
				return;
			}
			
			void dispatch(
				apiActions.call<GetInvoiceParameters, undefined, GetInvoiceResponse>({
					action: 'GET',
					uri: `/invoice`,
					callBeforeFinish: resolve,
					completion: (json) => {
						if (json.status === 'success') {
							setInvoice(json.response);
						} else {
							if (json.code === 'ENDPOINT_ERROR' && json.message.includes('Invoice not found')) {
								setNotFound(true);
								return;
							}
							
							dispatch(
								commonActions.showPageMessage({
									color: 'danger',
									title: 'Oh snap!',
									message: (
										<React.Fragment>
											{json.parameter ? `${json.parameter}: ` : ''}
											<span dangerouslySetInnerHTML={{ __html: parseMarkdown(json.message) }} />
										</React.Fragment>
									),
								})
							);
						}
					},
					parameters: {
						number: Number(number),
						secret,
					},
					handleErrorsAutomatically: false,
				})
			);
		});
	}, [dispatch, number, secret]);
	
	
	// Load invoice on mount
	useEffect(() => {
		void memoizedLoadInvoice();
	}, [memoizedLoadInvoice]);
	
	
	// Return JSX
	return (
		<SimplePageTemplate
			heading='View invoice'
			descriptor={
				invoice
					? invoice.details.paid
						? 'This invoice has been paid'
						: 'Please mail a check or pay via card'
					: notFound
					  ? 'Invoice couldn’t be found'
					  : 'Loading…'
			}
		>
			{notFound && <NotFound />}
			
			{!invoice && !notFound && (
				<LoadingWrapper>
					<FontAwesomeIcon icon={faRefresh} spin />
				</LoadingWrapper>
			)}
			
			{invoice && (
				<Content>
					<Box>
						<TopPart>
							<div>
								<LogoContainer>
									<Logo />
								</LogoContainer>
							</div>
							
							<div>
								<div>Invoice #: {invoice.details.number}</div>
								<div>
									Generated:{' '}
									{new Date(invoice.details.created).toLocaleDateString(undefined, {
										year: 'numeric',
										month: 'long',
										day: 'numeric',
										timeZone: 'America/Chicago',
									})}
								</div>
								<div>
									Payment due:{' '}
									{new Date(invoice.details.due).toLocaleDateString(undefined, {
										year: 'numeric',
										month: 'long',
										day: 'numeric',
										timeZone: 'UTC',
									})}
								</div>
							</div>
							<div>
								<div>{company.name}</div>
								<div>{invoice.mailing.streetAddress}</div>
								<div>
									{invoice.mailing.city}, {invoice.mailing.state} {invoice.mailing.zipCode}
								</div>
								<div>{company.supportNumber}</div>
							</div>
							
							<div>
								<div>{invoice.community.name}</div>
								<div>{invoice.subscription.invoiceRecipient.name}</div>
								<div>{invoice.subscription.invoiceRecipient.email}</div>
							</div>
						</TopPart>
						
						{invoice.details.transaction && (
							<TableContainer>
								<Table>
									<thead>
										<tr>
											<th style={{ textAlign: 'center' }}>
												{invoice.details.transaction.received ? 'Paid' : 'Payment processing…'}
											</th>
										</tr>
									</thead>
									
									<tbody>
										<tr>
											<td style={{ textAlign: 'center' }}>
												{invoice.details.transaction.received
													? new Date(invoice.details.transaction.received).toLocaleDateString(undefined, {
															year: 'numeric',
															month: 'long',
															day: 'numeric',
															timeZone: 'America/Chicago',
													  })
													: new Date(invoice.details.transaction.initiated).toLocaleDateString(undefined, {
															year: 'numeric',
															month: 'long',
															day: 'numeric',
															timeZone: 'America/Chicago',
													  })}{' '}
												via {invoice.details.transaction.sourceDescriptor}
											</td>
										</tr>
									</tbody>
								</Table>
							</TableContainer>
						)}
						
						<TableContainer>
							<Table>
								<thead>
									<tr>
										<th>Item</th>
										<th style={{ textAlign: 'right' }}>Amount</th>
									</tr>
								</thead>
								
								<tbody>
									<tr>
										<td>{invoice.details.descriptor}</td>
										<td style={{ textAlign: 'right' }}>{formatCurrency(invoice.details.amount)}</td>
									</tr>
									
									{Boolean(invoice.details.discount) && (
										<React.Fragment>
											<tr>
												<td style={{ textAlign: 'right', width: '100%' }}>
													<strong>Subtotal</strong>
												</td>
												
												<td style={{ textAlign: 'right', minWidth: '6.5rem' }}>
													<strong>{formatCurrency(invoice.details.amount)}</strong>
												</td>
											</tr>
											<tr>
												<td style={{ textAlign: 'right', width: '100%' }}>
													<strong>Discount</strong>
												</td>
												
												<td style={{ textAlign: 'right', minWidth: '6.5rem' }}>
													<strong>{formatCurrency((invoice.details.discount ?? 0) * -1)}</strong>
												</td>
											</tr>
										</React.Fragment>
									)}
									
									<tr>
										<td style={{ textAlign: 'right', width: '100%' }}>
											<strong>Total</strong>
										</td>
										
										<td style={{ textAlign: 'right', minWidth: '6.5rem' }}>
											<strong>{formatCurrency(invoice.details.total)}</strong>
										</td>
									</tr>
								</tbody>
							</Table>
						</TableContainer>
					</Box>
				</Content>
			)}
			
			{invoice && !invoice.details.transaction && !invoice.details.paid && (
				<Suspense fallback={null}>
					<PaymentForm
						onChange={memoizedLoadInvoice}
						storybookShowPaymentForm={storybookShowPaymentForm}
						storybookNumber={storybookNumber}
						storybookSecret={storybookSecret}
					/>
				</Suspense>
			)}
		</SimplePageTemplate>
	);
};

export default Invoice;
