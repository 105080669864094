// Imports
import styled from 'styled-components/macro';


// Styled components
const TabImage = styled.div`
	> svg {
		width: 31.25rem;
		
		@media (max-width: 70rem) {
			width: 25rem;
		}
		
		@media (max-width: 60rem) {
			width: 20rem;
		}
		
		@media (max-width: 48rem) {
			width: 85vw;
			max-width: 31.25rem;
		}
	}
`;

export default TabImage;
