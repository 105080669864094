// Imports
import React, { useRef } from 'react';
import styled, { css } from 'styled-components/macro';
import Button from '../button';
import Dropdown, { Chevron } from './dropdown';
import featureLinks from './feature-links';
import NavbarLink, { styling } from './link';
import resourceLinks from './resource-links';
import { usePreventScroll } from '@react-aria/overlays';
import { useAppSelector } from '../../../shareables/foundation/front-end/redux/hooks';
import generateUserDropdownLinks from './generate-user-dropdown-links';
import { AppCommonState } from '../../../redux/types';


// Styled components
const MenuButton = styled.button`
	border: 0;
	padding: 0;
	margin: 0;
	background-color: transparent;
	appearance: none;
	cursor: pointer;
	line-height: 1;
	color: hsl(206, 83%, 27%);
	z-index: 3;
	padding: 1.25rem;
	margin: -1.25rem;
	padding-bottom: 0;
	margin-bottom: 0;
	
	&& {
		box-sizing: content-box;
	}
	
	display: none;
	
	@media (max-width: 70rem) {
		height: 2.95rem;
		display: flex;
		align-items: center;
		font-size: 1.625rem;
	}
	
	@media (max-width: 48rem) {
		height: 2.197rem;
		font-size: 1.25rem;
		gap: 0.5rem;
		
		padding: 0.94rem;
		margin: -0.94rem;
	}
`;

const Hamburger = styled.span`
	display: block;
	
	@media (max-width: 70rem) {
		width: 1.422rem;
		height: 1.1875rem;
		font-size: 1.1875rem;
	}
	
	@media (max-width: 48rem) {
		width: 1.09375rem;
		height: 0.89875rem;
		font-size: 0.89875rem;
	}
`;

interface BarProps {
	rotateCounterClockwise?: boolean;
	rotateClockwise?: boolean;
	shrink?: boolean;
}

const Bar = styled.span<BarProps>`
	display: block;
	width: 100%;
	height: 0.125em;
	background-color: currentColor;
	border-radius: 9999rem;
	will-change: transform;
	transition: transform 0.4s cubic-bezier(0, 0, 0, 1) 0s;
	
	&:not(:last-child) {
		margin-bottom: 0.3125em;
	}
	
	${({ rotateClockwise }) =>
		rotateClockwise
			? css`
					transform: translateY(calc(-0.5em + 50%)) rotate(45deg);
			  `
			: ''}
	
	${({ rotateCounterClockwise }) =>
		rotateCounterClockwise
			? css`
					transform: translateY(calc(0.5em - 50%)) rotate(-45deg);
			  `
			: ''}
	
	${({ shrink }) =>
		shrink
			? css`
					transition-duration: 0.2s;
					transform: scaleX(0);
			  `
			: ''}
`;

const Menu = styled.span`
	font-size: 0.875rem;
	line-height: 1;
	display: none;
	
	@media (max-width: 48rem) {
		display: inline;
	}
`;

interface NavigationLinksProps {
	isOpen: boolean;
}

const NavigationLinks = styled.div<NavigationLinksProps>`
	${({ isOpen }) =>
		isOpen
			? css`
					transform: none;
					opacity: 1;
					pointer-events: auto;
			  `
			: css`
					transform: scale(0.98);
					opacity: 0;
					pointer-events: none;
			  `}
	
	z-index: 1;
	
	transition:
		opacity 0.2s ease-in-out,
		transform 0.2s ease-in-out;
	
	> a,
	button {
		font-size: 1.375rem;
	}
	
	a::after,
	button::after {
		display: none;
	}
	
	${Chevron} {
		font-size: 0.76388rem;
	}
	
	font-size: 1.375rem;
	display: none;
	flex-direction: column;
	
	background-color: hsl(0, 0%, 100%);
	box-shadow: 0 0 0 100vw hsl(0, 0%, 100%);
	clip-path: inset(0 -100vw);
	
	position: fixed;
	gap: 1.5rem;
	
	top: 0;
	left: 0;
	
	width: 100%;
	height: 100vh;
	padding-top: 6rem;
	padding-left: 1.25rem;
	padding-right: 1.25rem;
	padding-bottom: 1.875rem;
	overflow-y: auto;
	overflow-x: hidden;
	
	@media (max-width: 70rem) {
		display: flex;
	}
`;

const Demo = styled.a`
	${styling}
	font-size: inherit;
	width: fit-content;
	
	@media (max-width: 48rem) {
		margin-bottom: 0;
	}
`;

const SignInOrSignUpLinks = styled.div`
	display: none;
	text-align: center;
	
	@media (max-width: 48rem) {
		margin-top: 0.33rem;
		display: flex;
		gap: 0.9375rem;
		flex-direction: column;
	}
	
	> a {
		justify-content: center;
		padding: 0.625rem 1.25rem;
		
		&:last-child {
			font-weight: 400;
		}
	}
`;

const SignedInContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1.875rem;
	
	> a {
		display: none;
		text-align: center;
		justify-content: center;
		padding: 0.625rem 1.25rem;
		
		&:last-child {
			font-weight: 400;
		}
		
		@media (max-width: 48rem) {
			display: inline;
		}
	}
`;


// Define the accepted props
interface Props {
	activeDropdown: string | null;
	setActiveDropdown: React.Dispatch<React.SetStateAction<string | null>>;
	onLinkClick: () => void;
	storybookUser?: AppCommonState['user'];
}


// Function component
const MobileNavigation: React.FC<Props> = ({ activeDropdown, setActiveDropdown, onLinkClick, storybookUser }) => {
	// Use ref
	const menuButton = useRef<HTMLButtonElement>(null);
	
	
	// Use Redux functionality
	const user = useAppSelector((state) => storybookUser ?? state.common.user);
	
	
	// Decide if the menu should be open
	const isOpen = Boolean(activeDropdown?.startsWith('mobile-navigation'));
	
	
	// Handle key down
	const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
		if (e.key === 'Escape') {
			setActiveDropdown(null);
			menuButton.current?.focus();
		}
	};
	
	
	// Prevent scroll
	usePreventScroll({
		isDisabled: !isOpen,
	});
	
	
	// Return JSX
	return (
		<React.Fragment>
			<MenuButton
				type='button'
				aria-label='Toggle navigation menu'
				onClick={() => setActiveDropdown(isOpen ? null : 'mobile-navigation')}
				ref={menuButton}
			>
				<Menu>Menu</Menu>
				<Hamburger>
					<Bar rotateCounterClockwise={isOpen} />
					<Bar shrink={isOpen} />
					<Bar rotateClockwise={isOpen} />
				</Hamburger>
			</MenuButton>
			
			<NavigationLinks isOpen={isOpen} onKeyDown={handleKeyDown}>
				<Dropdown
					name='mobile-navigation-features'
					items={featureLinks}
					text='Features'
					activeDropdown={activeDropdown}
					setActiveDropdown={setActiveDropdown}
					columns={1}
				/>
				<NavbarLink to='/pricing' onClick={onLinkClick}>
					Pricing
				</NavbarLink>
				<Dropdown
					name='mobile-navigation-resources'
					items={resourceLinks}
					text='Resources'
					activeDropdown={activeDropdown}
					setActiveDropdown={setActiveDropdown}
					columns={1}
				/>
				<Demo href='https://demo.communitysite.com/' target='_blank' rel='noopener' onClick={onLinkClick}>
					Demo
				</Demo>
				
				{user.signedIn ? (
					<SignedInContainer>
						<Dropdown
							name='mobile-navigation-user'
							items={generateUserDropdownLinks(user, { excludeSignOut: true })}
							text='My account'
							activeDropdown={activeDropdown}
							setActiveDropdown={setActiveDropdown}
							columns={1}
						/>
						
						<Button
							href={`https://auth.${process.env.REACT_APP__ENVIRONMENT_HOSTNAME}/sign-out?redirect=${encodeURIComponent(
								window.location.origin
							)}`}
							rel='noopener'
							onClick={() => {
								onLinkClick();
								
								if (window.Intercom) {
									window.Intercom('shutdown');
								}
							}}
							backgroundColor='#fff'
							fontColor='hsl(206, 83%, 27%)'
							borderColor='hsl(206, 83%, 27%)'
							scale={0.982}
						>
							Sign out
						</Button>
					</SignedInContainer>
				) : (
					<SignInOrSignUpLinks>
						<Button
							href={`https://start.${process.env.REACT_APP__ENVIRONMENT_HOSTNAME}`}
							rel='noopener'
							onClick={onLinkClick}
							backgroundColor='hsl(96, 54%, 59%)'
							fontColor='#fff'
							scale={0.982}
							displayArrowIndicator
						>
							Start for free
						</Button>
						
						<Button
							href={`https://app.${process.env.REACT_APP__ENVIRONMENT_HOSTNAME}`}
							rel='noopener'
							onClick={onLinkClick}
							backgroundColor='#fff'
							fontColor='hsl(206, 83%, 27%)'
							borderColor='hsl(206, 83%, 27%)'
							scale={0.982}
						>
							Sign in
						</Button>
					</SignInOrSignUpLinks>
				)}
			</NavigationLinks>
		</React.Fragment>
	);
};

export default MobileNavigation;
