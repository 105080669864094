// Imports
import tabs, { Identifiers } from './tabs';
import styled from 'styled-components/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


// Styled components
const Wrapper = styled.div`
	width: 100%;
	
	display: flex;
	align-items: center;
	gap: 6.25rem;
	
	@media (max-width: 48rem) {
		flex-direction: column-reverse;
		justify-content: flex-end;
		align-items: center;
		gap: 3.2rem;
	}
`;

const Heading = styled.h2`
	font-size: 2rem;
	font-weight: 400;
	color: hsl(206, 83%, 27%);
	
	margin-bottom: 0.9375rem;
	
	> strong {
		display: block;
		font-weight: 600;
	}
`;

const Description = styled.p`
	font-size: 1.125rem;
	color: hsl(206, 25%, 20%);
	margin-bottom: 1.875rem;
`;

const Points = styled.ul`
	list-style: none;
	display: flex;
	flex-direction: column;
	gap: 1.25rem;
`;

const Point = styled.li`
	display: flex;
	align-items: center;
	gap: 0.9375rem;
	
	> div {
		min-width: 2.5rem;
		width: 2.5rem;
		height: 2.5rem;
		border-radius: 50%;
		
		background-color: hsl(96, 54%, 59%);
		
		display: flex;
		align-items: center;
		justify-content: center;
		
		font-size: 1.25rem;
		color: #fff;
	}
	
	> span {
		font-size: 1.375rem;
		color: hsl(206, 25%, 20%);
	}
`;


// Define the accepted props
type TabContent = (typeof tabs)[0]['content'];

interface Props extends TabContent {
	identifier: Identifiers;
	activeTab: Identifiers;
}


// Function component
const TabContent: React.FC<Props> = ({ identifier, activeTab, title, description, features, svg }) => {
	// Display nothing if content should not be visible
	if (identifier !== activeTab) {
		return null;
	}
	
	
	// Return JSX
	return (
		<Wrapper>
			<div>
				<Heading>
					{title.prefix}
					<strong>{title.emphasis}</strong>
				</Heading>
				
				<Description>{description}</Description>
				
				<Points>
					{features.map((feature, i) => (
						<Point key={i}>
							<div>
								<FontAwesomeIcon icon={feature.icon} />
							</div>
							
							<span>{feature.text}</span>
						</Point>
					))}
				</Points>
			</div>
			
			{svg}
		</Wrapper>
	);
};

export default TabContent;
