// Imports
import TabImage from './tab-image';


// Function component
const Galleries: React.FC = () => (
	<TabImage>
		<svg fill='none' viewBox='0 0 500 469' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
			<filter
				id='galleries-a'
				colorInterpolationFilters='sRGB'
				filterUnits='userSpaceOnUse'
				height='340'
				width='440'
				x='30'
				y='75'
			>
				<feFlood floodOpacity='0' result='BackgroundImageFix' />
				<feColorMatrix
					in='SourceAlpha'
					result='hardAlpha'
					type='matrix'
					values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
				/>
				<feOffset dy='10' />
				<feGaussianBlur stdDeviation='10' />
				<feComposite in2='hardAlpha' operator='out' />
				<feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0' />
				<feBlend in2='BackgroundImageFix' mode='normal' result='effect1_dropShadow_327_347' />
				<feBlend in='SourceGraphic' in2='effect1_dropShadow_327_347' mode='normal' result='shape' />
			</filter>
			<clipPath id='galleries-b'>
				<path d='m0 0h500v469h-500z' />
			</clipPath>
			<g clipPath='url(#galleries-b)'>
				<rect fill='#cce9ff' height='469' rx='10' width='500' />
				<g filter='url(#galleries-a)'>
					<rect fill='#fff' height='300' rx='5' shapeRendering='crispEdges' width='400' x='50' y='85' />
					<path d='m70 105h70v80h-70z' fill='#b5db9d' />
					<path
						d='m93.8281 126.25c1.25-1.484 3.5157-1.484 4.7657 0l9.0622 10.703c.469.547.547 1.328.235 2.031-.313.625-.938 1.016-1.641 1.016h-1.172l5.078 6.328c.469.625.547 1.406.235 2.031-.313.625-.938 1.016-1.641 1.016h-2.031l4.843 6.875h.313v-14.375c0-1.016.781-1.875 1.875-1.875 1.016 0 1.875.859 1.875 1.875v14.375h8.828l-5.391-7.656c-.39-.547-.468-1.328-.156-1.953.313-.625 1.016-1.016 1.719-1.016h1.719l-5.078-6.328c-.469-.547-.547-1.328-.235-1.953.313-.625.938-1.094 1.719-1.094h.938l-5.938-7.031-4.688 5.547-2.578-2.813 4.844-5.703c1.25-1.484 3.516-1.484 4.766 0l9.062 10.703c.469.547.547 1.328.235 2.031-.313.625-.938 1.016-1.641 1.016h-1.172l5.078 6.328c.469.625.547 1.406.235 2.031-.313.625-.938 1.016-1.641 1.016h-2.031l5.39 7.734c.391.547.469 1.329.157 1.953-.313.626-.938 1.016-1.641 1.016h-12.5v3.125c0 1.016-.859 1.875-1.875 1.875-1.094 0-1.875-.859-1.875-1.875v-3.203h-1.25-1.875-8.75-1.875v3.125c0 1.094-.8594 1.875-1.875 1.875-1.0938 0-1.875-.781-1.875-1.875v-3.125h-3.125-9.375c-.7031 0-1.4062-.391-1.7188-1.016-.3124-.625-.2343-1.328.1563-1.875l5.3906-7.734h-1.9531c-.7812 0-1.4062-.391-1.7188-1.016-.3124-.625-.2343-1.406.2344-2.031l5.0782-6.328h-1.0938c-.7812 0-1.4062-.391-1.7188-1.016-.3124-.703-.2343-1.484.2344-2.031zm6.1719 30h6.953l-5.391-7.656c-.39-.547-.468-1.328-.156-1.953.313-.625 1.016-1.016 1.719-1.016h1.719l-5.0784-6.328c-.4687-.547-.5468-1.328-.2344-1.953.3126-.625.9378-1.094 1.7188-1.094h.938l-5.938-7.031-6.0156 7.031h1.0156c.7031 0 1.3281.469 1.6406 1.094s.2344 1.406-.2344 1.953l-5.0781 6.328h1.7969c.7031 0 1.3281.391 1.6406 1.016s.2344 1.406-.1562 1.953l-5.3906 7.656h5.7812 3.125v-14.375c0-1.016.7812-1.875 1.875-1.875 1.0156 0 1.875.859 1.875 1.875v14.375z'
						fill='#3e7918'
					/>
					<path d='m150 105h80v80h-80z' fill='#7aa9cc' />
					<path
						d='m197.578 125.625 11.875 12.5c.547.547.625 1.328.391 2.031-.313.703-1.016 1.094-1.719 1.094h-3.828l7.656 8.125c.547.547.625 1.328.391 2.031-.313.703-1.016 1.094-1.719 1.094h-4.141l8.047 9.453c.469.547.547 1.328.235 2.031-.313.625-.938 1.016-1.641 1.016h-16.875c.781-1.016 1.25-2.344 1.25-3.75h11.562l-8.046-9.375c-.469-.547-.547-1.328-.235-2.031.313-.625.938-1.094 1.719-1.094h3.75l-7.656-8.047c-.469-.547-.625-1.328-.313-2.031.235-.703.938-1.172 1.719-1.172h3.75l-7.5-7.891-6.719 7.032-2.734-2.5 8.047-8.516c.39-.391.859-.625 1.406-.625.469 0 .938.234 1.328.625zm-31.016 17.891 10-9.141c1.954-1.719 4.844-1.719 6.797 0l10 9.141c1.016 1.015 1.641 2.343 1.641 3.75v12.734c0 2.812-2.266 5-5 5h-20c-2.812 0-5-2.188-5-5v-12.734c0-1.407.547-2.735 1.562-3.75zm2.188 3.75v12.734c0 .703.547 1.25 1.25 1.25h20c.625 0 1.25-.547 1.25-1.25v-12.734c0-.391-.156-.704-.469-.938l-10-9.219c-.469-.39-1.172-.39-1.64 0l-10 9.219c-.313.234-.391.547-.391.938zm7.5.859c0-1.016.781-1.875 1.875-1.875h3.75c1.016 0 1.875.859 1.875 1.875v3.75c0 1.094-.859 1.875-1.875 1.875h-3.75c-1.094 0-1.875-.781-1.875-1.875z'
						fill='#c9e3f5'
					/>
					<path d='m240 105h100v80h-100z' fill='#ffebcc' />
					<path
						d='m291.25 129.375v33.75c0 1.094-.859 1.875-1.875 1.875-1.094 0-1.875-.781-1.875-1.875v-33.75c0-2.344 1.953-4.375 4.375-4.375h8.75c2.344 0 4.375 2.031 4.375 4.375v10.625h2.5v-5.625c0-1.016.781-1.875 1.875-1.875 1.016 0 1.875.859 1.875 1.875v5.703c2.109.313 3.75 2.11 3.75 4.297v18.75c0 1.094-.859 1.875-1.875 1.875-1.094 0-1.875-.781-1.875-1.875v-18.75c0-.313-.312-.625-.625-.625h-1.25-6.25c-1.094 0-1.875-.781-1.875-1.875v-12.5c0-.313-.312-.625-.625-.625h-8.75c-.391 0-.625.312-.625.625zm2.5 3.125c0-.625.547-1.25 1.25-1.25h2.5c.625 0 1.25.625 1.25 1.25v2.5c0 .703-.625 1.25-1.25 1.25h-2.5c-.703 0-1.25-.547-1.25-1.25zm1.25 6.25h2.5c.625 0 1.25.625 1.25 1.25v2.5c0 .703-.625 1.25-1.25 1.25h-2.5c-.703 0-1.25-.547-1.25-1.25v-2.5c0-.625.547-1.25 1.25-1.25zm-1.25 8.75c0-.625.547-1.25 1.25-1.25h2.5c.625 0 1.25.625 1.25 1.25v2.5c0 .703-.625 1.25-1.25 1.25h-2.5c-.703 0-1.25-.547-1.25-1.25zm11.25-1.25h2.5c.625 0 1.25.625 1.25 1.25v2.5c0 .703-.625 1.25-1.25 1.25h-2.5c-.703 0-1.25-.547-1.25-1.25v-2.5c0-.625.547-1.25 1.25-1.25zm-1.25 8.75c0-.625.547-1.25 1.25-1.25h2.5c.625 0 1.25.625 1.25 1.25v2.5c0 .703-.625 1.25-1.25 1.25h-2.5c-.703 0-1.25-.547-1.25-1.25zm-21.25-17.5c0 .469-.078.859-.156 1.25 1.562 1.094 2.656 2.969 2.656 5 0 3.516-2.812 6.25-6.25 6.25h-1.25v12.5c0 1.406-1.172 2.5-2.5 2.5-1.406 0-2.5-1.094-2.5-2.5v-12.5h-1.25c-3.516 0-6.25-2.734-6.25-6.25 0-2.031 1.016-3.906 2.578-5-.078-.391-.078-.781-.078-1.25 0-4.141 3.281-7.5 7.5-7.5 4.141 0 7.5 3.359 7.5 7.5z'
						fill='#ba904f'
					/>
					<path d='m350 105h80v80h-80z' fill='#8bcf5f' />
					<path
						d='m410.156 136.719c-1.094-.781-1.718-2.188-1.484-3.594 0-.156.078-.391.078-.625 0-2.031-1.719-3.75-3.75-3.75-2.109 0-3.75 1.719-3.75 3.75v.625c.234 1.406-.312 2.813-1.484 3.594-.625.469-1.016 1.25-1.016 2.031 0 1.406 1.094 2.5 2.5 2.5h7.5c1.328 0 2.5-1.094 2.5-2.5 0-.781-.469-1.562-1.094-2.031zm2.344-4.219c0 .469-.078.859-.156 1.25 1.562 1.094 2.656 2.969 2.656 5 0 3.516-2.812 6.25-6.25 6.25h-1.875v18.125c0 1.094-.859 1.875-1.875 1.875-1.094 0-1.875-.781-1.875-1.875v-18.125h-1.875c-3.516 0-6.25-2.734-6.25-6.25 0-2.031 1.016-3.906 2.578-5-.078-.391-.078-.781-.078-1.25 0-4.141 3.359-7.5 7.5-7.5s7.5 3.359 7.5 7.5zm-41.25 13.75h17.5v-2.5h-17.5zm-3.75-3.75c0-1.328 1.094-2.5 2.5-2.5h20c1.328 0 2.5 1.172 2.5 2.5v5c0 1.172-.859 2.188-1.875 2.422v2.578h2.5c1.016 0 1.875.859 1.875 1.875 0 1.094-.859 1.875-1.875 1.875h-.625v6.875c0 1.094-.859 1.875-1.875 1.875-1.094 0-1.875-.781-1.875-1.875v-6.875h-17.5v6.875c0 1.094-.859 1.875-1.875 1.875-1.094 0-1.875-.781-1.875-1.875v-6.875h-.625c-1.094 0-1.875-.781-1.875-1.875 0-1.016.781-1.875 1.875-1.875h2.5v-2.578c-1.094-.234-1.875-1.25-1.875-2.422zm5.625 7.5v2.5h13.75v-2.5z'
						fill='#d3f6bc'
					/>
					<path d='m70 195h150v80h-150z' fill='#caaa79' />
					<path
						d='m106.969 222.188c-.235-.391-.781-.391-1.016 0l-1.562 2.578c-.079.156-.313.234-.547.234h-3.594c-.7031 0-1.3281-.547-1.1719-1.172.7031-3.75 4.5309-8.828 12.4219-8.828 6.875 0 10.625 3.906 11.953 7.344 1.953-1.328 4.609-2.344 8.047-2.344 7.812 0 11.641 5.078 12.344 8.828.156.625-.469 1.172-1.094 1.172h-3.672c-.234 0-.469-.078-.547-.234l-1.562-2.578c-.235-.391-.781-.391-1.016 0l-1.562 2.578c-.079.156-.313.234-.547.234h-8.047c2.187 6.484 3.359 14.766 2.656 22.266-.156 1.562-1.562 2.734-3.125 2.734h-6.562c-2.11 0-3.594-2.031-2.969-3.984 2.109-7.266 2.422-12.657 2.031-16.25-.078-1.016-.234-1.875-.39-2.578l-9.922 9.843c-.469.469-1.25.469-1.641-.078-2.109-3.125-2.969-9.375 2.578-14.922.313-.312.625-.625.938-.937zm14.843 7.812h-1.171c.468 1.25.781 2.656.937 4.375.391 4.063 0 9.687-1.953 16.875h5.156c.625-7.344-.781-15.391-2.969-21.25z'
						fill='#fae2bd'
					/>
					<path
						d='m154.969 222.188c-.235-.391-.781-.391-1.016 0l-1.562 2.578c-.079.156-.313.234-.547.234h-3.594c-.703 0-1.328-.547-1.172-1.172.703-3.75 4.531-8.828 12.422-8.828 6.875 0 10.625 3.906 11.953 7.344 1.953-1.328 4.609-2.344 8.047-2.344 7.812 0 11.641 5.078 12.344 8.828.156.625-.469 1.172-1.094 1.172h-3.672c-.234 0-.469-.078-.547-.234l-1.562-2.578c-.235-.391-.781-.391-1.016 0l-1.562 2.578c-.079.156-.313.234-.547.234h-8.047c2.187 6.484 3.359 14.766 2.656 22.266-.156 1.562-1.562 2.734-3.125 2.734h-6.562c-2.11 0-3.594-2.031-2.969-3.984 2.109-7.266 2.422-12.657 2.031-16.25-.078-1.016-.234-1.875-.39-2.578l-9.922 9.843c-.469.469-1.25.469-1.641-.078-2.109-3.125-2.969-9.375 2.578-14.922.313-.312.625-.625.938-.937zm14.843 7.812h-1.171c.468 1.25.781 2.656.937 4.375.391 4.063 0 9.687-1.953 16.875h5.156c.625-7.344-.781-15.391-2.969-21.25z'
						fill='#fae2bd'
					/>
					<path d='m230 195h100v80h-100z' fill='#b5db9d' />
					<path
						d='m271.484 218.75c-4.296 0-7.734 3.516-7.734 7.812 0 4.297 3.438 7.813 7.734 7.813 1.797 0 3.125 1.484 2.969 3.203v.625c-.078.859-.469 1.563-.937 2.109-1.094 1.172-1.719 2.735-1.719 4.376 0 3.046 2.109 5.624 4.922 6.406-1.094-1.563-1.797-3.516-1.797-5.547 0-1.953.625-3.906 1.719-5.469l2.734-3.906c2.813-3.984 4.375-8.75 4.375-13.672v-1.562c0-.547.391-.938.859-.938 1.172 0 2.188.625 2.891 1.562.625-.937 1.641-1.562 2.812-1.562.547 0 .938.391.938.938v1.562h.547c4.219 0 7.656 3.203 8.125 7.344v.625c.078.39 0 .703-.156 1.015l-.313.625c-1.094 2.266-3.281 3.829-5.703 4.141v4.219c1.875 1.484 3.125 3.828 3.125 6.406 0 1.641-.469 3.125-1.328 4.375h2.656c1.016 0 1.875.859 1.875 1.875 0 1.094-.859 1.875-1.875 1.875h-9.453-1.25-3.047-6.015c-5.704 0-10.313-4.609-10.313-10.312 0-2.5.859-4.844 2.344-6.641-5.86-.469-10.469-5.469-10.469-11.485 0-6.328 5.156-11.562 11.484-11.562 4.688 0 8.672 2.812 10.547 6.719.391.937 0 2.109-.937 2.5-.938.469-2.11.078-2.5-.938-1.25-2.656-3.985-4.531-7.11-4.531zm16.016 32.5h1.25c2.344 0 4.375-1.953 4.375-4.375 0-2.344-2.031-4.375-4.375-4.375-.469 0-.938.078-1.328.234-1.016.313-2.031-.234-2.344-1.25-.312-.937.234-2.031 1.172-2.343.781-.235 1.641-.391 2.5-.391.391 0 .781.078 1.25.156v-4.531c0-1.016.781-1.875 1.875-1.875h.859c1.407 0 2.735-.781 3.36-2.031l.078-.157v-.078c-.234-2.265-2.11-3.984-4.375-3.984h-.547-3.75-.312c-.547 4.375-2.188 8.516-4.688 12.109l-2.734 3.907c-.704.937-1.016 2.109-1.016 3.281 0 3.125 2.578 5.703 5.703 5.703zm2.5-22.5c0-.625.547-1.25 1.25-1.25.625 0 1.25.625 1.25 1.25 0 .703-.625 1.25-1.25 1.25-.703 0-1.25-.547-1.25-1.25z'
						fill='#3e7918'
					/>
					<path d='m340 195h90v80h-90z' fill='#94c7eb' />
					<path
						d='m379.453 223.125c-.078.469-.391.937-.781 1.172-.469.234-.938.312-1.406.234-.391-.078-.704-.078-1.016-.078-2.422 0-4.375 1.953-4.375 4.375 0 .469.078.938.234 1.406.235.86-.078 1.797-.937 2.266-1.484.781-2.422 2.344-2.422 4.062 0 2.657 2.031 4.688 4.688 4.688h9.687v-1.25-5-8.125c0-1.016.781-1.875 1.875-1.875 1.016 0 1.875.859 1.875 1.875v8.125 5 1.25h9.687c2.579 0 4.688-2.031 4.688-4.688 0-1.718-1.016-3.281-2.5-4.062-.859-.469-1.172-1.406-.859-2.266.078-.468.234-.937.234-1.484 0-2.344-2.031-4.375-4.375-4.375-.391 0-.703.078-1.016.156-.546.078-1.015 0-1.484-.234-.391-.235-.703-.703-.781-1.172-.625-2.5-2.813-4.375-5.469-4.375-2.734 0-4.922 1.875-5.547 4.375zm3.672 21.875h-9.687c-4.688 0-8.438-3.75-8.438-8.438 0-2.656 1.25-5.078 3.203-6.562-.078-.391-.078-.781-.078-1.25 0-4.453 3.594-8.125 8.125-8.125h.156c1.406-3.281 4.688-5.625 8.594-5.625 3.828 0 7.109 2.344 8.594 5.625h.156c4.453 0 8.125 3.672 8.125 8.125 0 .469-.078.859-.156 1.25 1.953 1.484 3.281 3.906 3.281 6.562 0 4.688-3.828 8.438-8.438 8.438h-9.687v8.125c0 1.094-.859 1.875-1.875 1.875-1.094 0-1.875-.781-1.875-1.875z'
						fill='#1c6296'
					/>
					<path d='m70 285h110v80h-110z' fill='#8bcf5f' />
					<path
						d='m126.828 305.703 3.906 4.297 4.219-3.359c.547-.391 1.328-.469 1.953-.157.625.313 1.094.938 1.094 1.641v8.75c0 5.937-4.766 10.625-10.625 10.625v6.719c3.203-4.063 8.125-6.719 13.75-6.719h2.187c1.172 0 2.188 1.016 2.188 2.188 0 8.515-6.875 15.312-15.312 15.312h-2.813-3.75-2.813c-8.515 0-15.312-6.797-15.312-15.312 0-1.172.938-2.188 2.188-2.188h2.187c5.547 0 10.547 2.656 13.75 6.719v-6.719c-5.937 0-10.625-4.688-10.625-10.625v-8.75c0-.703.391-1.328 1.016-1.641.625-.312 1.406-.234 1.953.235l4.297 3.281 3.828-4.297c.312-.469.859-.703 1.406-.703.469 0 1.016.234 1.328.703zm.547 18.047c3.75 0 6.875-3.047 6.875-6.875v-4.844l-2.656 1.953c-.782.625-1.875.547-2.5-.234l-3.594-3.984-3.594 3.984c-.703.781-1.797.859-2.578.234l-2.578-1.953v4.844c0 3.828 3.047 6.875 6.875 6.875zm-6.563 17.5h2.266c-1.64-5.703-6.953-10-13.203-10h-.547c.781 5.703 5.625 10 11.484 10zm9.376 0c5.859 0 10.624-4.297 11.406-10h-.469c-6.328 0-11.641 4.297-13.281 10z'
						fill='#d3f6bc'
					/>
					<path d='m190 285h110v80h-110z' fill='#7aa9cc' />
					<path
						d='m255.5 308.75c0 1.406-.781 2.578-1.875 3.281-1.172.703-2.656.703-3.75 0-1.172-.703-1.875-1.875-1.875-3.281 0-1.328.703-2.5 1.875-3.203 1.094-.703 2.578-.703 3.75 0 1.094.703 1.875 1.875 1.875 3.203zm-18.75 26.25c0-2.188-1.25-4.219-3.125-5.391-1.953-1.093-4.375-1.093-6.25 0-1.953 1.172-3.125 3.203-3.125 5.391 0 2.266 1.172 4.297 3.125 5.469 1.875 1.093 4.297 1.093 6.25 0 1.875-1.172 3.125-3.203 3.125-5.469zm-6.25-10c3.516 0 6.797 1.953 8.594 5 1.797 3.125 1.797 6.953 0 10-1.797 3.125-5.078 5-8.594 5-3.594 0-6.875-1.875-8.672-5-1.797-3.047-1.797-6.875 0-10 1.797-3.047 5.078-5 8.672-5zm36.25 10c0-2.188-1.25-4.219-3.125-5.391-1.953-1.093-4.375-1.093-6.25 0-1.953 1.172-3.125 3.203-3.125 5.391 0 2.266 1.172 4.297 3.125 5.469 1.875 1.093 4.297 1.093 6.25 0 1.875-1.172 3.125-3.203 3.125-5.469zm-6.25-10c3.516 0 6.797 1.953 8.594 5 1.797 3.125 1.797 6.953 0 10-1.797 3.125-5.078 5-8.594 5-3.594 0-6.875-1.875-8.672-5-1.797-3.047-1.797-6.875 0-10 1.797-3.047 5.078-5 8.672-5zm-13.359-8.281-5.86 4.297c-.703.546-.625 1.718.235 2.109l5.39 2.734c.625.313 1.094.938 1.094 1.641v10c0 1.094-.859 1.875-1.875 1.875-1.094 0-1.875-.781-1.875-1.875v-8.828l-4.453-2.188c-3.281-1.64-3.672-6.25-.703-8.515l5.859-4.297c1.875-1.328 4.531-1.016 6.016.781l2.89 3.672h4.141c1.016 0 1.875.859 1.875 1.875 0 1.094-.859 1.875-1.875 1.875h-5c-.625 0-1.172-.234-1.484-.703l-3.516-4.375c-.234-.235-.547-.235-.859-.078z'
						fill='#c9e3f5'
					/>
					<path d='m310 285h120v80h-120z' fill='#ffebcc' />
					<path
						d='m358.594 305.703 2.109 3.985 4.375-1.329c.469-.078.938 0 1.25.313.313.312.469.859.313 1.25l-1.329 4.375 2.5 1.328-4.296 3.437c.156-.468.234-.937.234-1.484 0-3.437-2.812-6.25-6.25-6.25-3.516 0-6.25 2.813-6.25 6.25 0 3.438 2.734 6.25 6.25 6.25h.234l-1.172.938-1.874 1.562-.469-.937-4.375 1.328c-.391.078-.938 0-1.25-.313-.313-.312-.391-.859-.313-1.25l1.328-4.375-3.984-2.109c-.391-.234-.703-.703-.703-1.094 0-.469.312-.937.703-1.172l3.984-2.109-1.328-4.375c-.078-.391 0-.938.313-1.25.39-.313.859-.391 1.25-.313l4.375 1.329 2.187-3.985c.156-.391.625-.703 1.094-.703s.859.312 1.094.703zm2.656 11.797c0 1.406-.781 2.578-1.875 3.281-1.172.703-2.656.703-3.75 0-1.172-.703-1.875-1.875-1.875-3.281 0-1.328.703-2.5 1.875-3.203 1.094-.703 2.578-.703 3.75 0 1.094.703 1.875 1.875 1.875 3.203zm16.172-4.531 16.875 13.75c.781.625.937 1.797.234 2.656-.625.781-1.797.859-2.656.234l-1.875-1.562v12.578c0 2.422-2.031 4.375-4.375 4.375h-18.75c-2.422 0-4.375-1.953-4.375-4.375v-12.578l-1.953 1.562c-.781.625-2.031.547-2.656-.234-.625-.859-.547-2.031.234-2.656l16.875-13.75c.703-.547 1.719-.547 2.422 0zm8.828 12.031-10-8.203-10 8.203v15.625c0 .391.234.625.625.625h18.75c.313 0 .625-.234.625-.625zm-13.75 4.375c0-1.016.781-1.875 1.875-1.875h3.75c1.016 0 1.875.859 1.875 1.875v3.75c0 1.094-.859 1.875-1.875 1.875h-3.75c-1.094 0-1.875-.781-1.875-1.875z'
						fill='#ba904f'
					/>
				</g>
			</g>
		</svg>
	</TabImage>
);

export default Galleries;
